import Mutasi from '../../data/mutasi-kas.json';
import Bulan from '../../data/bulan.json';
import Table from 'react-bootstrap/Table'
import { Card, Row, Col, Stack, Dropdown, Badge, Container } from 'react-bootstrap'
import { useState } from 'react'
import SaldoBulanan from '../../data/saldo-bulanan.json'
import PengeluaranPerKategori from '../../data/pengeluaran-perkategori.json'
import { totalmem } from 'os';

export default function MutasiKas() {
    const mutasi = Mutasi;
    const bulan = Bulan;

    const [selectedBulan, setSelectedBulan] = useState(bulan[0].value);
    const handleBulanChange = (event: any) => {
        setSelectedBulan(event.target.value);
    }



    const kasKeluar = mutasi.filter((value) => value.bulan.toString() === selectedBulan.toString() && value.tahun.toString() === '2024')
        .reduce((sum, value) => sum + value.kasKeluar, 0)

    const kasMasuk = mutasi.filter((value) => value.bulan.toString() === selectedBulan.toString() && value.tahun.toString() === '2024')
        .reduce((sum, value) => sum + value.kasMasuk, 0)

    const saldoBulanan = SaldoBulanan
    const pengeluaranPerKategori = PengeluaranPerKategori

    const TotalkasKeluar = pengeluaranPerKategori.reduce((sum, value) => sum + value.kasKeluar, 0)


    return (
        <>
            <Card className='shadow bg-white rounded '>
                <Card.Header>
                    <Row>
                        <Col className='d-flex justify-content-between'>
                            <strong>SALDO BULANAN</strong>
                        </Col>
                    </Row>
                </Card.Header>

                <Table striped className='info-text m-1'>
                    <thead>
                        <th>BULAN</th>
                        <th>KAS MASUK</th>
                        <th>KAS KELUAR</th>
                        <th>SALDO</th>
                    </thead>
                    <tbody>
                        {
                            saldoBulanan.map((listingData, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{listingData.tahun.toString()} - {listingData.bulan.toString()}</td>
                                        <td align='right'>{listingData.kasMasuk.toLocaleString()}</td>
                                        <td align='right'>{listingData.KasKeluar.toLocaleString()}</td>
                                        <td align='right'>{listingData.saldo.toLocaleString()}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>

            </Card>
            <Card className='shadow bg-white rounded'>
                <Card.Header>
                    <Row>
                        <Col className='d-flex justify-content-between'>
                            <strong>PENGELUARAN</strong>
                            <Badge bg="success">
                                <h6>SEPTEMBER 2024</h6>
                            </Badge>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Table striped className='info-text'>
                        <thead>
                            <th>NO.</th>
                            <th>KATEGORI PENGELUARAN</th>
                            <th>NOMINAL</th>
                        </thead>
                        <tbody>
                            {
                                pengeluaranPerKategori.map((listingData, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{(i + 1).toString()}</td>
                                            <td><span className='text-right'>{listingData.PosKategori.toString()}</span></td>
                                            <td align='right'>{listingData.kasKeluar.toLocaleString()}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td></td>
                                <td></td>
                                <td align='right'>{TotalkasKeluar.toLocaleString()}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Card className='shadow bg-white rounded'>
                <Card.Header>
                    <Row>
                        <Col className='d-flex justify-content-between'>
                            <strong>DETAIL MUTASI</strong>
                            <Badge bg="success">
                                <h6>2024</h6>
                            </Badge>
                        </Col>
                    </Row>
                </Card.Header>
                <div className='info-text m-1'>
                    <select
                        value={selectedBulan}
                        onChange={handleBulanChange}
                    >
                        {
                            bulan.map(namaBulan => (
                                <option key={namaBulan.value} value={namaBulan.value}>
                                    {namaBulan.text}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <Table striped bordered className='info-text m-1'>
                    <thead>
                        <th>TANGGAL</th>
                        <th>KETERANGAN</th>
                        <th>KAS MASUK</th>
                        <th>KAS KELUAR</th>
                        <th></th>
                    </thead>
                    <tbody>
                        {
                            mutasi.filter((value) => {
                                return value.bulan.toString() === selectedBulan.toString() && value.tahun.toString() === '2024';
                            })
                                .sort(function (a: any, b: any) { return a.blok - b.blok })
                                .map((dataMutasi, i) => (
                                    <>
                                        <tr key={i} className='info-text'>
                                            <td className='infoText'>{dataMutasi.tanggal.toString()}</td>
                                            <td>
                                                <span className='d-block'>{dataMutasi.atasNama.toString()}</span>
                                                <span className='d-block'>{dataMutasi.blok.toString()}</span>
                                                <span className='d-block'>{dataMutasi.rekening.toString()}</span>
                                                {dataMutasi.keterangan.toString()}
                                            </td>
                                            <td align='right'>{dataMutasi.kasMasuk.toLocaleString()}</td>
                                            <td align='right'>{dataMutasi.kasKeluar.toLocaleString()}</td>
                                            <td><input type='checkbox' defaultChecked={false}></input></td>
                                        </tr>
                                    </>
                                ))
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td>{kasMasuk.toLocaleString()}</td>
                            <td>{kasKeluar.toLocaleString()}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>

            </Card>
        </>
    )
}