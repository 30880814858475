import { Col, Container, Row, Stack } from "react-bootstrap";
import MutasiKas from "../mutasi-kas";
import SaldoKas from "../saldo_kas";
import ProgressKas from "../progress_kas";
import Tunggakan from "../tunggakan";
import KartuIuran from "../kartu_iuran";
import RekeningKas from "../info/rekening";
import Badge from 'react-bootstrap/Badge';

export default function KasWarga() {
    return (
        <div className='pb-2'>
            <div className='bg-white pt-3 pb-2'>
                <Container>
                    <blockquote className="blockquote text-center">
                        <p className="mb-3"><strong>Update data per tanggal 30 September 2024</strong>, silahkan bagi yang masih ada tunggakan untuk segera melakukan pembayaran.</p>
                        <footer className="blockquote-footer">Pengurus Komplek<cite title="Source Title">BCA</cite></footer>
                        <div className="text-white info-text bg-danger">Mohon untuk tidak membagikan halaman ini kepada selain warga komplek Bumi Cileunyi Asri</div>
                    </blockquote>
                
                </Container>
            </div>
            <Container>
                <Row xs={1} className="g-4">
                    <Col>
                        <Stack gap={3} className='pt-3'>
                            <Tunggakan />
                            <SaldoKas />
                            <ProgressKas />
                            <KartuIuran />
                            <MutasiKas />
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}